<template>
    <div class="container">
        <keep-alive v-if="$route.meta.keepAlive">
            <router-view></router-view>
        </keep-alive>
        <router-view v-else></router-view>

        <!-- tabBar -->
        <TabBar :data="tabbars" @change="handleChange" />

    </div>
</template>

<script>
import TabBar from '@/components/TabBar'
export default {
    name: 'AppLayout',
    data() {
        return {
            tabbars: [
                // {
                //     title: '首页',
                //     to: {
                //         name: 'Home'
                //     },
                //     icon: 'home-o'
                // },
                // {
                //     title: '列表',
                //     to: {
                //         name: 'List'
                //     },
                //     icon: 'user-o'
                // },
                // {
                //     title: 'vant组件', // 菜单标题
                //     to: {
                //         name: 'Vant'
                //     },
                //     icon: 'user-o'
                // },
              {
                title: '报名', // 菜单标题
                to: {
                  name: 'Sign'
                },
                icon: 'user-o'
              }
            ]
        }
    },
    components: {
        TabBar
    },
    methods: {
        handleChange(v) {
            console.log('tab value:', v)
        }
    }
}
</script>
